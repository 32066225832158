import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgramService } from './views/shared/program.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { AppRoutes } from './app.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { environment } from '../environments/environment';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { ToastrModule } from 'ngx-toastr';  /* for toastr */
import { CookComponent } from './views/dashboard/cook/cook.component';
// import { ProgramComponent } from './views/dashboard/cook/program/program.component';
import { ParametersComponent } from './views/dashboard/cook/parameters/parameters.component';
import { TempsComponent } from './views/dashboard/cook/temps/chart.component';
import { SetupComponent } from './views/dashboard/setup/setup.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProgramListComponent } from './views/dashboard/cook/program/program-list.component'; /* for toastr */
import { MatIconModule, MatButtonModule, MatGridListModule, MatInputModule, MatTableModule,
         MatSlideToggleModule, MatSelectModule, MatCardModule } from '@angular/material';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import 'hammerjs';
import { GaugeComponent } from './views/dashboard/cook/temps/gauge.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { HighchartsChartModule } from "highcharts-angular";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CookComponent,
    // ProgramComponent,
    ParametersComponent,
    TempsComponent,
    SetupComponent,
    PageNotFoundComponent,
    NavbarComponent,
    ProgramListComponent,
    GaugeComponent
  ],
  imports: [
    BrowserModule,
    HighchartsChartModule,
    // IonicModule.forRoot(AppComponent),
    AppRoutes,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatGridListModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatCardModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    UiSwitchModule
   ],
  providers: [ AuthService, AuthGuard, ProgramService ],
  bootstrap: [AppComponent]
})
export class AppModule {}
