      import { Injectable, OnDestroy } from '@angular/core';
      import { Router } from '@angular/router';
      import { AngularFireAuth } from 'angularfire2/auth';
      import { AngularFireDatabase, AngularFireObject } from 'angularfire2/database';
      import * as firebase from 'firebase/app';
      import { Observable } from 'rxjs/Observable';
      import { ProgramService } from '../views/shared/program.service';
      import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { shiftInitState } from '@angular/core/src/view';

      @Injectable()
      export class AuthService {
        _photon: string;
        isLoginSubject = new BehaviorSubject<string>(this._photon);
        public user: Observable<firebase.User>;
        itemRef: AngularFireObject<any>;
        public userDetails: firebase.User = null;
        public LOGGEDIN: boolean = null;

        ownersRef = firebase.database().ref().child('owners');

        constructor(private _firebaseAuth: AngularFireAuth, private router: Router, private programService: ProgramService,
                    private db: AngularFireDatabase) {

          this.user = _firebaseAuth.authState;
          this.user.subscribe(
            (user) => {
              if (user) {
                this.getPhoton(user);
                this.userDetails = user;
                console.log(user.displayName + ' is logged in!');
                this.LOGGEDIN = true;
                this.router.navigate(['dashboard']);
              } else {
                this.userDetails = null;
              }
            }
          );
        }

      getPhoton(user) {
      // retrieves "controller" name from the logged in firebase user that is "next'ed" to an Observable/BehaviorSubject
        if (user) {
          this.ownersRef.orderByChild('userId').equalTo(user.uid).once('value')
          .then((snap) => {
            snap.forEach(data => {
            const photon2 = data.val().controller;
            this.isLoginSubject.next(photon2);
            // localStorage.setItem('controller', data.val().controller); // not using this option anymore... but works!
            });
          });
        }
      }

      isLoggedIn() {
        if (this.userDetails == null ) {
          this.LOGGEDIN = false;

          return false;
          } else { // console.log('IsLoggedIn() fired: Logged In !!!');
            return true;
        }
      }

      logout() {
        this.LOGGEDIN = false;
        console.log('LOGGED OUT!');
        this.userDetails = null;
        this._firebaseAuth.auth.signOut()
        .then((res) => this.router.navigate(['/']));
      }

      signInWithTwitter() {
        return this._firebaseAuth.auth.signInWithPopup(
          new firebase.auth.TwitterAuthProvider()
        );
      }

      signInWithFacebook() {
        return this._firebaseAuth.auth.signInWithPopup(
          new firebase.auth.FacebookAuthProvider()
        );
      }

      signInWithGoogle() {
        return this._firebaseAuth.auth.signInWithPopup(
          new firebase.auth.GoogleAuthProvider()
        );
      }

      signInWithGithub() {
        return this._firebaseAuth.auth.signInWithPopup(
          new firebase.auth.GithubAuthProvider()
        );
      }

      signInRegular(email, password) {
        const credential = firebase.auth.EmailAuthProvider.credential( email, password );
        return this._firebaseAuth.auth.signInWithEmailAndPassword(email, password);
      }

    }
