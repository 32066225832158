import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TableDataSource, ValidatorService } from 'angular4-material-table';
import { AuthService } from '../../../../services/auth.service';
import { TempsService } from '../../../shared/temps.service';
import { Temps } from '../../../shared/temps.model';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class TempsComponent implements OnInit {

  private controller: string;
  @ViewChild('chart') el: ElementRef;
  tempslist: Temps[];
  tempsCurrent: Temps[];

  constructor(private tempsService: TempsService, public authService: AuthService) { }

  ngOnInit() {

    this.authService.isLoginSubject.subscribe((photon) => {
      console.log(photon + ' is registered controller in temps.component');
      this.controller = photon;
    });

    const x = this.tempsService.getGraphTemps(this.controller);
    x.snapshotChanges().subscribe(item => {
      this.tempslist = [];
      item.forEach(element => {
        const y = element.payload.toJSON();
        y['$key'] = element.key;
        this.tempslist.push(y as Temps);
      });
      Plotly.purge(this.el.nativeElement);
      console.log(this.tempslist);
      this.tempsChart(this.tempslist);
    });

  }

  tempsChart(data) {
    const element = this.el.nativeElement;

    const target = {
      type: 'scatter',
      mode: 'lines',
      name: 'target',
      x: data.map(d => d.published_at),
      y: data.map(d => d.TT),
      line: {color: '#DB7365'}
    };

    const grill = {
      type: 'scatter',
      mode: 'lines',
      name: 'grill',
      x: data.map(d => d.published_at),
      y: data.map(d => d.T1),
      line: {color: '#17BECF'}
    };

    const meat1 = {
      type: 'scatter',
      mode: 'lines',
      name: 'meat1',
      x: data.map(d => d.published_at),
      y: data.map(d => d.T2),
      line: {color: '#7F7F7F'}
    };

    const meat2 = {
      type: 'scatter',
      mode: 'lines',
      name: 'meat2',
      x: data.map(d => d.published_at),
      y: data.map(d => d.T3),
      line: {color: '#1FFF7F'}
    };

    const formattedData = [target, grill, meat1, meat2];

    const WIDTH_IN_PERCENT_OF_PARENT = 60;
    const HEIGHT_IN_PERCENT_OF_PARENT = 80;

    const style = {
      margin: { t: 5, l: 20, r: 20, b: 5 },
      legend: { orientation: 'h'},
      style: {
        width: WIDTH_IN_PERCENT_OF_PARENT + '%',
        'margin-left': (100 - WIDTH_IN_PERCENT_OF_PARENT) / 2 + '%',

        height: HEIGHT_IN_PERCENT_OF_PARENT + 'vh' + 10,
        'margin-top': (100 - HEIGHT_IN_PERCENT_OF_PARENT) / 2 + 'vh'
      }
    };

    Plotly.plot(element, formattedData, style, {displayModeBar: false});

    window.onresize = function() {
      Plotly.Plots.resize(element);
    };

  }

}
