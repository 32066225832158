import { ParametersService } from './../../../shared/parameters.service';
import { Component, OnInit, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { TableDataSource, ValidatorService } from 'angular4-material-table';
import { Program } from '../../../shared/program.model';
import { ProgramService } from '../../../shared/program.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../../services/auth.service';


@Component({
  selector: 'app-program-list',
  templateUrl: './program-list.component.html',
  styleUrls: ['./program-list.component.css'],
  providers: [ {provide: ValidatorService, useClass: ProgramService} ]
})

export class ProgramListComponent implements OnInit {

  programlist: Program[];
  private controller: string;
  public pgmToggleState = false;

  constructor(private programService: ProgramService, private parametersService: ParametersService,
    public authService: AuthService, private tostr: ToastrService, private programValidator: ValidatorService) { }

  displayedColumns = ['mode', 'target', 'trigger', 'triggerValue', 'actionsColumn'];

  @Output() programListChange = new EventEmitter<Program[]>();
  dataSource: TableDataSource<Program>;

  ngOnInit() {

    this.authService.isLoginSubject.subscribe((photon) => {
      console.log(photon + ' is registered controller in program-list component');
      this.controller = photon;
    });

    this.programService.isProgramSubject.subscribe((pgmToggle) => {
      this.pgmToggleState = pgmToggle;
    });

    this.programService.getProgramToggle(this.controller);

    const x = this.programService.getData(this.controller);
    x.snapshotChanges().subscribe(item => {
      this.programlist = [];
      item.forEach(element => {
        const y = element.payload.toJSON();
        y['$key'] = element.key;
        this.programlist.push(y as Program);
        this.dataSource = new TableDataSource<any>(this.programlist, Program, this.programValidator);
        this.dataSource.datasourceSubject.subscribe(programlist => this.programListChange.emit(programlist));
      });
    });

  }

  programToggle() {
    this.programService.updatePgm(this.pgmToggleState);
    this.tostr.warning('Program state set to: ' + this.pgmToggleState);
  }

  onInsert() {
    const pgm = new Program;
    this.programService.insertProgram(pgm);
    // console.log('insert here');
  }

  onEdit(pgm: Program) {
    this.programService.selectedProgram = Object.assign( {}, pgm );
  }

  onUpdate(pgm: Program) {
    this.programService.updateProgram(pgm);
  }

  onDelete(key: string) {
    if (confirm('Are you sure to delete this program ?') === true) {
      this.programService.deleteProgram(key);
      this.tostr.warning('Deleted Successfully', 'Program Register');
    }
  }

  onProgram() {
    console.log('Programs executing');
  }

}
