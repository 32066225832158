import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ProgramService } from '../shared/program.service';
import { Program } from '../shared/program.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  control: string;
  constructor(public authService: AuthService, public router: Router, public programService: ProgramService) { }

  ngOnInit() {

  }

}
