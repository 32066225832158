import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ProgramService } from '../../shared/program.service';
import { TempsService } from '../../shared/temps.service';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-cook',
  templateUrl: './cook.component.html',
  styleUrls: ['./cook.component.css'],
  // providers: [ProgramService]

})
export class CookComponent implements OnInit {

  controller: string;

  constructor(private tempsService: TempsService,
              private programService: ProgramService,
              public authService: AuthService,
              public tostr: ToastrService,
              public router: Router) { }

  ngOnInit() {

    this.authService.isLoginSubject.subscribe((photon) => {
      console.log(photon + ' is registered controller in cook.component');
      this.controller = photon;
    });

  }

  purgeTemps() {
    if (confirm('Are you sure you want to purge all Temperature readings for ' + this.controller + '? ') === true) {
      this.tempsService.clearTemps(this.controller);
      this.tostr.success('Temperature readings purged successfully', 'Pellet Pirate Alert');
    }
  }

}
