import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AuthGuard } from './services/auth-guard.service';
import { SetupComponent } from './views/dashboard/setup/setup.component';
import { CookComponent } from './views/dashboard/cook/cook.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';

const appRoutes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardComponent
  },
  {
    path: 'setup',
    component: SetupComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cook',
      component: CookComponent,
      canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent }
];

export const AppRoutes = RouterModule.forRoot(appRoutes);

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes),
    CommonModule,
    AppRoutes
  ],
  exports: [
    RouterModule
  ],
  providers: [ AuthGuard ],
  declarations: [ ]
})
export class DashboardRoutesModule { }
