import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AngularFireDatabase, AngularFireObject } from 'angularfire2/database';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/take';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})


export class ParametersService {

  reff: AngularFireObject<any>;
  paramz$: Observable<any>;

   _pmodechanged: number ;
   isPModeSubject = new BehaviorSubject<number>(this._pmodechanged);

  _fanChecked: boolean;
  isFanSubject = new BehaviorSubject<boolean>(this._fanChecked);

  _ignChecked: boolean;
  isIgnSubject = new BehaviorSubject<boolean>(this._ignChecked);

  _augChecked: boolean;
  isAugSubject = new BehaviorSubject<boolean>(this._augChecked);

  _newMode: string;
  isNewModeSubject = new BehaviorSubject<string>(this._newMode);

  _newTarget: number;
  isNewTargetSubject = new BehaviorSubject<number>(this._newTarget);

  constructor(private db: AngularFireDatabase) { }

  getParameters(controller) {
    this.reff = this.db.object('ControllerData/' + controller + '/Parameters');
    this.paramz$ = this.reff.valueChanges();
    this.paramz$.subscribe(data => {
      console.log(data);
      const pmodeValueInFirebase = data.PMode;
      const fanValueInFirebase = data.fan;
      const ignValueInFirebase = data.ign;
      const augValueInFirebase = data.aug;
      const newModeValueInFirebase = data.mode;
      const newTargetValueInFirebase = data.target;
      this.isPModeSubject.next(pmodeValueInFirebase);
      this.isFanSubject.next(fanValueInFirebase);
      this.isIgnSubject.next(ignValueInFirebase);
      this.isAugSubject.next(augValueInFirebase);
      this.isNewModeSubject.next(newModeValueInFirebase);
      this.isNewTargetSubject.next(newTargetValueInFirebase);
    });
  }

   updatePMode(pmode) {
     console.log('Program Toggle changed in Web App to:' + pmode);
    this.reff.update({PMode: pmode});
   }

  updateFan(fanToggle) { // the ability to change this in the web app is turned off via the word 'disabled' in the html code
    console.log('Fan Toggle changed in Web App to:' + fanToggle);
    this.reff.update({fan: fanToggle});
  }

  updateIgn(ignToggle) { // the ability to change this in the web app is turned off via the word 'disabled' in the html code
    console.log('Igniter Toggle changed in Web App to:' + ignToggle);
    this.reff.update({ign: ignToggle});
  }

  updateAug(augToggle) {  // the ability to change this in the web app is turned off via the word 'disabled' in the html code
    console.log('Auger Toggle changed in Web App to:' + augToggle);
    this.reff.update({aug: augToggle});
  }

  updateMode(newMode) {
  console.log('Mode changed in Web App to:' + newMode);
  this.reff.update({mode: newMode});
}

  updateTarget(newTarget: number) {
    console.log('Target changed in Web App to:' + newTarget);
    this.reff.update({target: newTarget});
  }

}
