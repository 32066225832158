export class Program {
  $key: string;
  mode: 'Off';
  target: number;
  trigger: string;
  triggerValue: number;
}

export class Owner {
  $key: string;
  controller: string;
  email: string;
  name: string;
  photo: string;
  userId: string;
}
