import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase2 from 'firebase/app';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class TempsService {

  _grill: number;
  _meat1: number;
  _meat2: number;
  currentSubjectGrill = new BehaviorSubject<number>(this._grill);
  currentSubjectMeat1 = new BehaviorSubject<number>(this._meat1);
  currentSubjectMeat2 = new BehaviorSubject<number>(this._meat2);

  tempsList: AngularFireList<any>;

  constructor(private firebase: AngularFireDatabase) { }

  getGraphTemps(controller) {
   this.tempsList = this.firebase.list('ControllerData/' + controller + '/Temps');
    return this.tempsList;
  }

  getLastTemps(controller) {
    const Ref = this.firebase.database.ref('ControllerData/' + controller + '/Temps');
    Ref.limitToLast(1).on('child_added', (snap) => {
    this._grill = Number(Number(snap.val().T1).toFixed(1));
    this._meat1 = Number(Number(snap.val().T2).toFixed(1));
    this._meat2 = Number(Number(snap.val().T3).toFixed(1));
    // console.log('this_grill:' + this._grill);
    this.currentSubjectGrill.next(this._grill);
    this.currentSubjectMeat1.next(this._meat1);
    this.currentSubjectMeat2.next(this._meat2);
      });
    }

  clearTemps(controller) {
    const TempsRef = firebase2.database().ref().child('ControllerData/' + controller + '/Temps');
    TempsRef.remove();
  }

}
