import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

  private authState: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    public afAuth: AngularFireAuth, private db: AngularFireDatabase) {
      this.afAuth.authState.subscribe((auth) => {
        this.authState = auth;
      });
    }

    get isAuthenticated(): boolean {
      return this.authState !== null;
  }

  canActivate() {
    if  ( this.authService.isLoggedIn() ) {
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }

}
