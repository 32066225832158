import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent {
  items: Array<any> = [];

  constructor(public authService: AuthService, router: Router) {
    localStorage.removeItem('firebase:previous_websocket_failure');
  }
}
