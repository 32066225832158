import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { Program } from './program.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidatorService } from 'angular4-material-table';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/take';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';


@Injectable()
export class ProgramService implements ValidatorService {

  ref: AngularFireObject<any>;
  params$: Observable<any>;

  _pgmchecked: boolean;
  isProgramSubject = new BehaviorSubject<boolean>(this._pgmchecked);

  programList: AngularFireList<any>;
  selectedProgram: Program = new Program();

  constructor(private firebase: AngularFireDatabase) { }

  getProgramToggle(controller) {
    this.ref = this.firebase.object('ControllerData/' + controller + '/Parameters');
    this.params$ = this.ref.valueChanges();
    this.params$.subscribe(data => {
      const checkedValueInFirebase = data.pgm;
      this.isProgramSubject.next(checkedValueInFirebase);
      // console.log(data);
     });
  }

  updatePgm(programToggle) {
    console.log('Program Toggle changed in Web App to:' + programToggle);
    this.ref.update({pgm: programToggle});
  }

  getRowValidator(): FormGroup {
    return new FormGroup( {
      'mode': new FormControl(),
      'target': new FormControl(),
      'trigger': new FormControl(),
      'triggerValue': new FormControl()
      });
  }

  getData(controller) {
    // console.log('getData localStorage value: ' + localStorage.getItem('controller'));
    this.programList = this.firebase.list('ControllerData/' + controller + '/Program');
    return this.programList;
  }

  insertProgram(program: Program) {
    this.programList.push({
      mode: program.mode = 'Off',
      target: program.target = 0,
      trigger: program.trigger = 'Grill-Temp',
      triggerValue: program.triggerValue = 0 ,
    });
  }

  deleteProgram(key: string) {
    this.programList.remove(key);
  }

  updateProgram(pgm: Program) {
    this.programList.update(pgm.$key, {
      mode: pgm.mode,
      target: pgm.target,
      trigger: pgm.trigger,
      triggerValue: pgm.triggerValue,
    });
  }

}
