import { TempsService } from './../../../shared/temps.service';
import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, Pipe } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
// import { MatGridList } from '@angular/material';
import { AuthService } from '../../../../services/auth.service';
import { Temps } from '../../../shared/temps.model';

const Highcharts = require('highcharts/highstock');
require('highcharts/highmaps');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
import * as solidGauge from 'highcharts/modules/solid-gauge';

// now init the modules
solidGauge(Highcharts);

@Component({
 selector: 'app-gauge',
 styleUrls: [ './gauge.component.css'],
 templateUrl: './gauge.component.html'
})

export class GaugeComponent implements AfterViewInit, OnInit {

  private controller: string;
  _grill: number;
  _meat1: number;
  _meat2: number;
  tempslist: Temps[];

  // TRY NEW GAUGE 04/07/2019 https://codesandbox.io/s/o908q1llw6
  @ViewChild('grillGauge') public gaugeGrill: ElementRef;
  @ViewChild('meat1Gauge') public meat1Grill: ElementRef;
  @ViewChild('meat2Gauge') public meat2Grill: ElementRef;
  public chartValue = 0;

  private gaugegrill;
  private meat1grill;
  private meat2grill;

  public gaugeOptions = {credits: {enabled: false}, chart: {type: 'solidgauge'}, title: null,
    pane: { center: ['50%', '85%'], size: '140%', startAngle: -90, endAngle: 90,
      background: {backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
      innerRadius: '60%', outerRadius: '100%', shape: 'arc'} },
    tooltip: {enabled: false},
    navigation: {buttonOptions: {enabled: false}},
    yAxis: {stops: [[0.1, '#55BF3B'], [0.5, '#DDDF0D'], [0.9, '#DF5353']], // greeen to yellow to red on measures
      lineWidth: 0, min: 0, max: 500, tickAmount: 6, labels: {y: 16} },
    plotOptions: {solidgauge: {dataLabels: { y: 5, borderWidth: 0, useHTML: true } } },
    series: [{name: 'Grill', data: [0],
      dataLabels: {format: '<div style="text-align:center"><span style="font-size:25px;color:' +
        ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'red') + '">{y}</span><br/>' +
        '<span style="font-size:14px;color:silver">Grill F°</span></div>' } } ]
  };

  public meat1GaugeOptions = {credits: {enabled: false}, chart: {type: 'solidgauge'}, title: null,
    pane: {center: ['50%', '85%'], size: '140%', startAngle: -90, endAngle: 90,
      background: {backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
        innerRadius: '60%', outerRadius: '100%', shape: 'arc'} },
    tooltip: {enabled: false},
    navigation: {buttonOptions: {enabled: false} },
    yAxis: {stops: [ [0.1, '#55BF3B'], [0.5, '#DDDF0D'], [0.9, '#DF5353'] ], // green yellow red gauge band
      lineWidth: 0, min: 0, max: 250, tickAmount: 11, labels: {y: 16} },
    plotOptions: {solidgauge: {dataLabels: {y: 5, borderWidth: 0, useHTML: true} } },
    series: [ {name: 'Meat-1', data: [0],
      dataLabels: {format: '<div style="text-align:center"><span style="font-size:25px;color:' +
        ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'red') + '">{y}</span><br/>' +
        '<span style="font-size:14px;color:silver">Meat-1 F°</span></div>' } } ]
  };

  public meat2GaugeOptions = {credits: {enabled: false}, chart: {type: 'solidgauge'}, title: null,
    pane: {center: ['50%', '85%'], size: '140%', startAngle: -90, endAngle: 90,
      background: {backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
        innerRadius: '60%', outerRadius: '100%', shape: 'arc'} },
    tooltip: {enabled: false}, navigation: {buttonOptions: {enabled: false} },
    yAxis: {stops: [ [0.1, '#55BF3B'], [0.5, '#DDDF0D'], [0.9, '#DF5353'] ], // green, yellow red gauge band
      lineWidth: 0, min: 0, max: 250, tickAmount: 11, labels: {y: 16} },
    plotOptions: {solidgauge: {dataLabels: {y: 5, borderWidth: 0, useHTML: true} } },
    series: [ {name: 'Meat2', data: [0],
      dataLabels: {format: '<div style="text-align:center"><span style="font-size:25px;color:' +
        ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'red') + '">{y}</span><br/>' +
        '<span style="font-size:14px;color:silver">Meat-2 F°</span></div>' } } ]
  };

  constructor(private tempsService: TempsService, private observableMedia: ObservableMedia, public authService: AuthService) { }

  ngOnInit() {
    this.authService.isLoginSubject.subscribe((photon) => {
      console.log(photon + ' is registered controller in gauge.component');
      this.controller = photon;
      // this.tempsService.getGaugeTemps(this.controller);
    });

    this.tempsService.getLastTemps(this.controller);

  }

  ngAfterViewInit(): void {

    this.highchartsShow();
    if (this.tempsService.currentSubjectGrill !== undefined) {
      this.tempsService.currentSubjectGrill.subscribe((grill) => {
        this._grill = grill;
        // console.log('ngAfterViewInit: %d', this._grill);
        this.setGuageValueGrill(this._grill);
      });
    }

    if (this.tempsService.currentSubjectMeat1 !== undefined) {
      this.tempsService.currentSubjectMeat1.subscribe((meat1) => {
        this._meat1 = meat1;
        this.setGuageValueMeat1(this._meat1);
      });
    }

    if (this.tempsService.currentSubjectMeat2 !== undefined) {
      this.tempsService.currentSubjectMeat2.subscribe((meat2) => {
        this._meat2 = meat2;
        this.setGuageValueMeat2(this._meat2);
      });
    }

  }

  highchartsShow() {

    this.gaugeOptions.chart['renderTo'] = this.gaugeGrill.nativeElement;
    this.gaugegrill = Highcharts.chart(this.gaugeOptions);

    this.meat1GaugeOptions.chart['renderTo'] = this.meat1Grill.nativeElement;
    this.meat1grill = Highcharts.chart(this.meat1GaugeOptions);

    this.meat2GaugeOptions.chart['renderTo'] = this.meat2Grill.nativeElement;
    this.meat2grill = Highcharts.chart(this.meat2GaugeOptions);

  }

  setGuageValueGrill(value: number) {
    this.gaugegrill.series[0].setData([value]);
  }

  setGuageValueMeat1(value: number) {
    this.meat1grill.series[0].setData([value]);
  }

  setGuageValueMeat2(value: number) {
    this.meat2grill.series[0].setData([value]);
  }

}
