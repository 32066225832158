import { Component, OnInit, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ParametersService } from '../../../shared/parameters.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../../services/auth.service';
import { AngularFireDatabase, AngularFireObject} from 'angularfire2/database';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/take';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.css']
})

export class ParametersComponent implements OnInit {

  private controller: string;
  ref: AngularFireObject<any>;
  params$: Observable<any>;
  public pmodeState: number;
  public fanToggleState = false;
  public ignToggleState = false;
  public augToggleState = false;
  public modeSelected = 'Off';
  public newTarget: number;
  public newPMode: number;

  myFormGroup = new FormGroup({
    formField: new FormControl()
  });

  _value = 225;
  _step = 5;
  _min = 150;
  _max = 475;
  _wrap = false;
  color = 'default';
  breakpoint = 0;

  @Input('value')
  set inputValue(_value: number) {
    this._value = this.parseNumber(_value);
  }

  @Input()
  set step(_step: number) {
    this._step = this.parseNumber(_step);
  }

  @Input()
  set min(_min: number) {
    this._min = this.parseNumber(_min);
  }

  @Input()
  set max(_max: number) {
    this._max = this.parseNumber(_max);
  }

  @Input()
  set wrap(_wrap: boolean) {
    this._wrap = this.parseBoolean(_wrap);
  }

  constructor(private db: AngularFireDatabase, private parametersService: ParametersService,
    public authService: AuthService, private tostr: ToastrService) { }

  ngOnInit() {

    this.breakpoint = (window.innerWidth < 400) ? 1 : 2;

    this.authService.isLoginSubject.subscribe((photon) => {
      console.log(photon + ' is registered controller in parameters component');
      this.controller = photon;
    });

    this.parametersService.isPModeSubject.subscribe((pmodeValue) => {
      this.pmodeState = pmodeValue;
    });

    this.parametersService.isFanSubject.subscribe((fanToggle) => {
      this.fanToggleState = fanToggle;
    });

    this.parametersService.isIgnSubject.subscribe((ignToggle) => {
      this.ignToggleState = ignToggle;
    });

    this.parametersService.isAugSubject.subscribe((augToggle) => {
      this.augToggleState = augToggle;
    });

    this.parametersService.isNewModeSubject.subscribe((newMode) => {
      this.modeSelected = newMode;
    });

    this.parametersService.isNewTargetSubject.subscribe((newTar) => {
      this._value = newTar;
    });

    this.parametersService.getParameters(this.controller);

  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth < 400) ? 1 : 2;
  }

  fanToggle() { // the ability to change this in the web app is turned off via the word 'disabled' in the html code
    this.parametersService.updateFan(this.fanToggleState);
    this.tostr.warning('Fan state set to: ' + this.fanToggleState);
  }

  ignToggle() { // the ability to change this in the web app is turned off via the word 'disabled' in the html code
    this.parametersService.updateIgn(this.ignToggleState);
    this.tostr.warning('Igniter state set to: ' + this.ignToggleState);
  }

  augToggle() { // the ability to change this in the web app is turned off via the word 'disabled' in the html code
    this.parametersService.updateAug(this.augToggleState);
    this.tostr.warning('Auger state set to: ' + this.augToggleState);
  }

  changeMode() {
      this.parametersService.updateMode(this.modeSelected);
      this.tostr.success('Cook Mode set to: ' + this.modeSelected, 'Pellet Pirate Alert');
  }

  changeTarget() {
    this.parametersService.updateTarget(this._value);
    this.tostr.success('Target set to: ' + this._value, 'Pellet Pirate Alert');
  }

  changePMode(val: number): void {
    this.newPMode = val;
    this.parametersService.updatePMode(this.newPMode);
    this.tostr.success('PMode set to: ' + this.newPMode, 'Pellet Pirate Alert');
  }

  private parseNumber(num: any): number {
    return +num;
  }

  private parseBoolean(bool: any): boolean {
    return !!bool;
  }

  setColor(color: string): void {
    this.color = color;
  }

  getColor(): string {
    return this.color;
  }

  setIncrement(stepValue: number): void {
    this._step = stepValue;
  }

  incrementValue(step: number = 1): void {

    let inputValue = this._value + step;

    if (this._wrap) {
      inputValue = this.wrappedValue(inputValue);
    }

    this._value = inputValue;
    // console.log("in incrementValue() Proc");
    this.changeTarget();
  }

  private wrappedValue(inputValue): number {
    if (inputValue > this._max) {
      return this._min + inputValue - this._max;
    }

    if (inputValue < this._min) {

      if (this._max === Infinity) {
        return 0;
      }

      return this._max + inputValue;
    }

    return inputValue;
  }

  shouldDisableDecrement(inputValue: number): boolean {
    return !this._wrap && inputValue <= this._min;
  }

  shouldDisableIncrement(inputValue: number): boolean {
    return !this._wrap && inputValue >= this._max;
  }

}
